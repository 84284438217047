<template>
  <div class="page-container">
    <header-tab activeIndex="-1"></header-tab>
    <div class="settle-container">
      <div class="settle-inner">
        <div
          align="center"
          class="settle-in-h"
        >
          <img
            class="settle-head-title"
            v-lazy="require('../../assets/images/title20.png')"
          />
        </div>
        <div
          class="settle-in-b"
          v-lazy:background-image="{src: require('../../assets/images/lunbo4.png')}"
        >
          针对九为（浙江）网络科技有限公司旗下九医云平台（以下简称平台），在业务开展过程中对入驻经营者的要求和规范如下： <br />
          1、参与平台入驻经营的各主体应当依法办理市场主体登记，未办理市场主体登记的入驻申请，平台不予审核通过。 <br />
          2、商家应根据平台要求提供相应基础资质、经营资质、品牌资质等。<br />
          <span class="ssi1">基础资质</span><br />
          营业执照或营业执照登载信息或其他合法经营证照;<br />
          银行开户证明或相关信息﹔<br />
          法定代表人和/或商家负责人和/或商家联系人身份证(正反面)等。<br />
          <span class="ssi1">经营资质</span><br />
          从事许可类经营或经营许可类商品的,商家应根据电子商务平台要求提供经营许可资质和/或商品许可或批文等资质证明或信息。包含但不限于;药品经营许可证，二类器械经营许可
          证、三类器械经营许可证、食品经营许可证等。<br />
          <span class="ssi1">品牌资质</span><br />
          具有所经营品牌所有权的证明材料，或取得所经营品牌的商标注册文件,进货凭证或(品牌)权利人认可的一级或多级完整授权(多级授权的级数应满足电子商务平台的要求)。
          通过品牌授权书形式授权的,应加盖商标权利人的公章。通过合同形式授权的,可加盖商标权利人的合同章。商标权利人为自然人(包含法定代表人)的,应提供其亲笔签名的授权书和身份证复印件。<br />
          对于部分无法取得品牌权利人授权材料的进口品牌,商家应提供中华人民共和国海关进口货物报关单,报关单上标识的品牌信息应与商家经营的品牌信息一致。商家与报关单上的收、发货人或消费使用单位/生产销售单位不一致的,应提供报关单上的收发货人或消费使用单位/生产销售单位授权商家的一级或多级完整授权材料。<br />
          3、平台入驻经营者所销售的商品或者提供的服务应当符合保障人身、财产安全的要求和环境保护要求，不得销售或者提供法律、行政法规禁止交易的商品或者服务。平台须对经营者所上架的商品进行严格审核和不定期抽检，对不符合规定的产品予以下架或清退该入驻经营者。<br />
          4、平台入驻经营者所销售商品或者提供服务，不履行合同义务或者履行合同义务不符合约定，或者造成他人损害的，依法承担民事责任。且须根据消费者所造成损失进行相应赔偿，平台有责任和权利对经营者进行经济处罚或关闭店铺处罚。<br />
          5、平台入驻经营者应当全面、真实、准确、及时地披露商品或者服务信息，保障消费者的知情权和选择权。平台入驻经营者不得以虚构交易、编造用户评价等方式进行虚假或者引人误解的商业宣传，欺骗、误导消费者。对于不符合要求的入驻经营者，平台可采取书面警告、暂停店铺和品牌清退等方式进行处罚。<br />
          6、平台入驻经营者根据消费者的兴趣爱好、消费习惯等特征向其提供商品或者服务的搜索结果的，应当同时向该消费者提供不针对其个人特征的选项，尊重和平等保护消费者合法权益。平台入驻经营者向消费者发送广告的，应当遵守《中华人民共和国广告法》的有关规定。违规操作的平台入驻经营者，平台给予五万至十万元的罚款。<br />
          7、平台入驻经营者搭售商品或者服务，应当以显著方式提请消费者注意，不得将搭售商品或者服务作为默认同意的选项。违规操作的平台入驻经营者，平台给予五万至二十万元的罚款。<br />
          8、平台入驻经营者销售商品或者提供服务应当依法出具纸质发票或者电子发票等购货凭证或者服务单据。电子发票与纸质发票具有同等法律效力。<br />
          9、平台入驻经营者应当按照承诺或者与消费者约定的方式、时限向消费者交付商品或者服务，并承担商品运输中的风险和责任。但是，消费者另行选择快递物流服务提供者的除外。<br />
        </div>
        <div class="settle-pio">
          <div class="d1">
            <img
              class="m1"
              v-lazy="require('./images/qyxxsh.png')"
            />
            <div align="center">
              <img
                class="m1-m"
                v-lazy="require('./images/01.png')"
              />
            </div>
            <div>
              <ul class="uu-st">
                <li><span class="m1-ss2"></span><span class="m1-ss1">企业名称</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">企业联系人</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">品牌LOGO</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">座机号码</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">实际经营地址</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">手机号码</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">企业坐标</span></li>
              </ul>
            </div>
          </div>
          <div class="d1">
            <img
              class="m1"
              v-lazy="require('./images/qyzzsh.png')"
            />
            <div align="center">
              <img
                class="m1-m"
                v-lazy="require('./images/02.png')"
              />
            </div>
            <div>
              <ul class="uu-st">
                <li><span class="m1-ss2"></span><span class="m1-ss1">营业执照照片</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">主体名称</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">统一社会信用代码</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">法定代表人</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">经营场所/住所</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">营业执照有效期</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">注册/成立时间</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">注册资本</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">发证/登记机关</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">核准日期</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">药品经营许可证登记</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">第二类医疗器械经营许可证登记</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">第三类医疗器械经营许可证登记</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">食品经营许可证登记</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">互联网药品信息服务资格证登记</span></li>
              </ul>
            </div>
          </div>
          <div class="d1">
            <img
              class="m1"
              v-lazy="require('./images/fddbrsh.png')"
            />
            <div align="center">
              <img
                class="m1-m"
                v-lazy="require('./images/03.png')"
              />
            </div>
            <div>
              <ul class="uu-st">
                <li><span class="m1-ss2"></span><span class="m1-ss1">法人身份证照片</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">法人姓名</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">法人联系电话</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">法人身份证号码</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">性别</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">民族</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">出生日期</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">住址</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">签发机关</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">证件有效期</span></li>
                <li><span class="m1-ss2"></span><span class="m1-ss1">企业开户行信息</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
export default {
  name: "settlement",
  components: {
    "header-tab": () => import("@/components/headerTab"),
    "nav-footer": () => import("@/components/navFooter"),
  },
};
</script>

<style lang="less" scoped>
.settle-container {
  padding-top: 60px;
  padding-bottom: 70px;
}
.settle-inner {
  width: 68%;
  margin: 0 auto;
}
.settle-in-h {
  padding-top: 1.6rem;
}
.settle-in-b {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 2;
  margin-top: 30px;
  background-repeat: no-repeat;
  background-size: contain;
}
.settle-head-title {
  width: 12rem;
}
.ssi1 {
  font-size: 15px;
  font-weight: 500;
}
.settle-pio {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  .d1 {
    width: 10.2rem;
    box-shadow: 0px 2px 10px 2px rgba(74, 74, 74, 0.1);
    padding-bottom: 1rem;
    .m1 {
      display: block;
      width: 100%;
    }
    .m1-m {
      width: 3.3rem;
    }
  }
}
.uu-st {
  list-style: none;
  width: calc(100% - 36px);
  margin: 0px;
  margin-left: 36px;
  padding: 0px;
  li {
    float: left;
    min-width: 50%;
    span {
      display: inline-block;
    }
    .m1-ss1 {
      font-size: 14px;
      margin-left: 10px;
    }
    .m1-ss2 {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #6aa5ff;
    }
  }
}
</style>